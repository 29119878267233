import React from 'react';
import { Link } from 'gatsby';
import blog1 from '../assets/images/38.jpg';
import blog2 from '../assets/images/40.jpg';
import blog3 from '../assets/images/3.jpg';
const BlogThree = () => {
  return (
    <section className='blog-one  blog-one__home-two'>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>
            Our latest newsletters <br />& articles
          </h2>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blog1} alt='' />
                <a className='blog-one__plus' href='../news/application-extension'>
                  <i className='kipso-icon-plus-symbol'></i>
                </a>
              </div>
              <div className='blog-one__content text-center'>
                <div className='blog-one__meta'>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Posted On Jan 19'
                    href='#none'
                  >
                    <i className='fa fa-calendar-alt'></i>
                  </a>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='No Comments'
                    href='#none'
                  >
                    <i className='fa fa-comments'></i>
                  </a>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Posted By Admin'
                    href='#none'
                  >
                    <i className='fa fa-user'></i>
                  </a>
                </div>
                <h2 className='blog-one__title'>
                  <Link to='../news/application-extension'>
                    Update on Closure of LUTH Schools Application
                  </Link>
                </h2>
                <p className='blog-one__text'>
                  The application into LUTH schools has not closed for all
                  schools. The date updates for the schools...
                </p>
                <a
                  href='../news/application-extension'
                  className='blog-one__link'
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blog2} alt='' />
                <a className='blog-one__plus' href='/schools-list'>
                  <i className='kipso-icon-plus-symbol'></i>
                </a>
              </div>
              <div className='blog-one__content text-center'>
                <div className='blog-one__meta'>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Posted On Jan 19'
                    href='#none'
                  >
                    <i className='fa fa-calendar-alt'></i>
                  </a>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='No Comments'
                    href='#none'
                  >
                    <i className='fa fa-comments'></i>
                  </a>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Posted By Admin'
                    href='#none'
                  >
                    <i className='fa fa-user'></i>
                  </a>
                </div>
                <h2 className='blog-one__title'>
                  <a href='/schools-list'>
                    Application into LUTH Schools is Closed
                  </a>
                </h2>
                <p className='blog-one__text'>
                  Admissions is now closed for entry into the various LUTH schools.
                  Click below to Apply
                </p>
                {/* <a href='/schools-list' className='blog-one__link'>
                  Apply Now
                </a> */}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blog3} alt='' />
                <a className='blog-one__plus' href='news-details.html'>
                  <i className='kipso-icon-plus-symbol'></i>
                </a>
              </div>
              <div className='blog-one__content text-center'>
                <div className='blog-one__meta'>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Posted On Jan 19'
                    href='#none'
                  >
                    <i className='fa fa-calendar-alt'></i>
                  </a>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='No Comments'
                    href='#none'
                  >
                    <i className='fa fa-comments'></i>
                  </a>
                  <a
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Posted By Admin'
                    href='#none'
                  >
                    <i className='fa fa-user'></i>
                  </a>
                </div>
                <h2 className='blog-one__title'>
                  <a href='../news/exam-date-nursing'>
                    Examination Date for Nursing Examination
                  </a>
                </h2>
                <p className='blog-one__text'>
                  All applicants should take note that, the Entrance Exam into
                  College of Nursing, LUTH will hold on
                </p>
                <a href='../news/exam-date-nursing' className='blog-one__link'>
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogThree;
